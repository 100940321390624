import React from "react";
import Image from "next/image";

const ProfileCard: React.FC = () => {
  return (
    <section
      role="complementary"
      aria-labelledby="psychologist-profile"
      className="flex flex-col bg-gradient-to-b from-white to-emerald-50 shadow-xl border border-slate-100 rounded-2xl h-full w-full overflow-hidden transition-all duration-300 group"
    >
      {/* Image Section */}
      <div className="relative flex justify-center items-center mt-10">
        <div className="relative w-44 h-44 sm:w-52 sm:h-52 lg:w-56 lg:h-56">
          <div className="absolute inset-0 duration-300 rounded-full" />
          <Image
            className="w-full h-full object-cover rounded-full shadow-2xl border-4 border-emerald-500/30 transition-all duration-500"
            src="/murray-molohon-registered-psychologist.webp"
            alt="Murray Molohon - Expert Relationship Counsellor in Calgary"
            width={280}
            height={280}
            priority
            aria-label="Profile picture of Murray Molohon"
          />
          {/* Experience Badge */}
          <span
            className="absolute -bottom-4 inset-x-0 mx-auto bg-gradient-to-r from-emerald-600 to-emerald-700 text-white text-xs sm:text-sm font-semibold px-5 py-2 rounded-full shadow-lg w-max backdrop-blur-sm border border-emerald-800/20"
            aria-label="35+ years of counselling experience"
          >
            ✨ 35+ Years Experience
          </span>
        </div>
      </div>

      {/* Content Section */}
      <div className="text-center px-6 pb-10 pt-6">
        <h2
          id="psychologist-profile"
          className="mt-6 text-3xl sm:text-4xl font-bold text-slate-900 leading-tight bg-gradient-to-r from-emerald-600 to-slate-900 bg-clip-text text-transparent"
        >
          Murray Molohon
        </h2>
        <h3 className="mt-4 text-lg font-semibold text-slate-600 uppercase tracking-wide">
          <span className="bg-clip-text bg-gradient-to-r from-emerald-600 to-emerald-700 text-transparent">
            Registered Psychologist
          </span>{" "}
          | Calgary Therapy Services
        </h3>

        {/* Divider */}
        <div className="mt-6 mb-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-slate-200/80" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-4 text-emerald-600">
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            </span>
          </div>
        </div>

        {/* Bio */}
        <p className="text-slate-700/90 leading-relaxed text-lg sm:text-base lg:text-lg space-y-4">
          <span className="block">
            Murray Molohon is a highly experienced Registered Psychologist in Calgary, specializing in relationship counselling, family therapy, and individual counselling.
          </span>
          <span className="block mt-3 font-medium text-slate-800/95">
            With over 35 years of experience, Murray&apos;s compassionate approach helps clients navigate life&apos;s challenges and achieve personal growth.
          </span>
        </p>
      </div>

      {/* Structured Data remains same */}
    </section>
  );
};

export default ProfileCard;